.no-scroll {
  overflow: hidden;
}
.no-scroll ::-webkit-scrollbar {
  display: none;
}
.te-dialog,
.te-styled .te-dialog {
  scroll-behavior: smooth;
  background: #b4b4b4;
  background-color: rgba(180, 180, 180, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 8000;
  overflow-y: scroll;
  min-width: 300px;
  padding: 10px;
  max-width: 100vw;
}
.te-dialog-card,
.te-styled .te-dialog-card {
  -webkit-box-shadow: 5px 5px 5px -4px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 5px 5px 5px -4px rgba(0, 0, 0, 0.25);
  align-items: stretch;
  background: white;
  box-shadow: 5px 5px 5px -4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  margin: 0 auto;
  max-width: 1350px;
  overflow: scroll;
  position: relative;
  z-index: 9000;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  -webkit-backface-visibility: hidden;
}
.te-dialog-card.display-block,
.te-styled .te-dialog-card.display-block {
  display: block;
}
.te-dialog-card.display-none,
.te-styled .te-dialog-card.display-none {
  display: none;
}
.te-dialog-inner,
.te-styled .te-dialog-inner {
  align-items: stretch;
  box-sizing: border-box;
  display: block;
  flex: 1;
  height: auto;
  padding-top: 50px;
}
.te-dialog-inner-product,
.te-styled .te-dialog-inner-product {
  margin: 0;
  padding: 0;
}
.te-dialog-inner-product.te-xlarge,
.te-styled .te-dialog-inner-product.te-xlarge {
  margin-top: 10px;
  height: 600px;
  max-height: 600px;
}
.te-dialog-inner-product.te-large,
.te-styled .te-dialog-inner-product.te-large {
  margin-top: 10px;
  height: 550px;
  max-height: 550px;
}
.te-dialog-header-fixed,
.te-styled .te-dialog-header-fixed {
  background-color: white;
  z-index: 9999;
  display: flex;
  align-items: center;
  width: 94%;
  max-width: 1350px;
  padding: 10px 0 10px 0;
  height: 50px;
  top: 0;
  border-bottom: none;
  position: absolute;
  box-sizing: border-box;
}
.te-dialog-header-fixed.te-mobile,
.te-styled .te-dialog-header-fixed.te-mobile {
  border-bottom: 1px solid #e7e7e7;
}
.te-dialog-header-fixed.on-modal-scroll,
.te-styled .te-dialog-header-fixed.on-modal-scroll {
  top: 10px;
  border-bottom: 1px solid #e7e7e7;
  position: fixed;
}
.te-dialog-header-fixed.on-modal-scroll::after,
.te-styled .te-dialog-header-fixed.on-modal-scroll::after {
  content: '';
  top: -10px;
  left: 0;
  width: 102%;
  height: 10px;
  background-color: #d9d9d9;
  z-index: 9999999;
  position: absolute;
}
.te-dialog-header-fixed-logo,
.te-styled .te-dialog-header-fixed-logo {
  position: relative;
  margin-right: auto;
}
.te-dialog-header-fixed-logo-wrapper,
.te-styled .te-dialog-header-fixed-logo-wrapper {
  margin-left: 20px;
}
.te-dialog-header-fixed-logo-img,
.te-styled .te-dialog-header-fixed-logo-img {
  max-width: 250px;
  max-height: 25px;
}
.te-dialog-header-fixed-cross,
.te-styled .te-dialog-header-fixed-cross {
  width: 20px;
  position: relative;
  margin-right: 10px;
  top: unset;
  right: unset;
  color: #4f5058;
}
.te-dialog-header-fixed .te-dialog-header-fixed-cart,
.te-styled .te-dialog-header-fixed .te-dialog-header-fixed-cart {
  width: 30px;
  height: 30px;
  margin-right: 13px;
}
.te-dialog-header-fixed .te-dialog-header-fixed-cart svg,
.te-styled .te-dialog-header-fixed .te-dialog-header-fixed-cart svg {
  height: 24px;
  margin-top: 4px;
}
.te-dialog-header-fixed .te-dialog-header-fixed-cart span,
.te-styled .te-dialog-header-fixed .te-dialog-header-fixed-cart span {
  left: 0;
  bottom: -2px;
}
@-webkit-keyframes scaleout {
  0% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}
@keyframes scaleout {
  0% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
    opacity: 0;
  }
}
.te-tipser-product-wrapper {
  min-height: 50vh;
}
.te-product-card {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  font-size: inherit;
  overflow: hidden;
  padding: 10px;
}
a.te-product-card:hover {
  color: inherit;
}
.te-product-card .te-button {
  line-height: 2.08em;
  min-width: auto;
}
.te-product-card .te-button-text {
  font-size: 0.9em;
  font-weight: 400;
}
.te-product-card .te-button.disabled {
  border: 1px solid #eee;
}
.te-product-card .te-button.disabled .te-button-body {
  background-color: #f2f2f2;
}
.te-product-card .te-button.disabled .te-button-text {
  color: #a3a0a0;
}
.te-product-card .te-product-discount-label {
  display: none;
}
.te-product-card .te-product-card-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 2em;
  padding: 15px 0;
  justify-content: flex-start;
  overflow: hidden;
  position: relative;
  height: auto;
  cursor: default;
}
.te-product-card .te-product-card-img {
  width: 100%;
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 0.3s ease;
}
.te-product-card .te-product-card-img-invalid {
  background-color: #dddddd;
  width: 75%;
  max-width: 225px;
  margin: 0 auto;
  height: 100%;
}
.te-product-card .te-product-card-img:hover {
  opacity: 0.9;
}
.te-product-card .te-product-card-name {
  font-size: 1em;
  font-weight: normal;
  line-height: 1.3em;
  white-space: nowrap;
  text-overflow: ellipsis;
  letter-spacing: 0px;
  color: #5f5f5f;
  overflow: hidden;
  max-width: 100%;
  text-align: left;
  display: inline-block;
}
.te-product-card .te-product-card-name:hover {
  color: #525860;
}
.te-product-card .te-product-card-brand {
  font-size: 1em;
  cursor: default;
  font-weight: normal;
  letter-spacing: 0;
  color: #a3a0a0;
  text-transform: uppercase;
  white-space: nowrap;
}
.te-product-card .te-product-card-price {
  align-items: center;
  color: #5f5f5f;
  display: flex;
  font-size: 1.08em;
  font-weight: 600;
  justify-content: center;
  margin-top: 0.5em;
}
.te-product-card .te-product-card-unit-price {
  color: #afafaf;
  line-height: 1em;
  margin-bottom: 1em;
}
.te-product-card .te-product-card-sales-price {
  color: #ec877b;
  font-weight: 600;
  font-size: 1.08em;
}
.te-product-card .te-product-card-regular-price {
  font-size: 1em;
  font-weight: 400;
  margin-left: 10px;
  text-decoration: line-through;
}
.te-product-card-noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
.te-product-card.te-product-card-compact {
  max-width: 300px;
  width: 100%;
  display: block;
  padding: 0;
  margin: 30px auto;
  font-size: inherit;
}
.te-product-card.te-product-card-compact .te-button {
  font-size: 1em;
}
.te-product-card.te-product-card-compact .te-product-card-img.te-img {
  height: 300px;
}
.te-product-card.te-product-card-compact.te-product-card-large {
  max-width: calc(300px * 1.2);
}
.te-product-card.te-product-card-compact.te-product-card-large .te-product-card-img.te-img {
  height: calc(300px * 1.2);
}
.te-product-card.te-product-card-compact.te-product-card-small {
  max-width: calc(300px * 0.8);
}
.te-product-card.te-product-card-compact.te-product-card-small .te-product-card-img.te-img {
  height: calc(300px * 0.8);
}
.te-collection .te-product-card {
  padding: 15px;
}
.te-collection .te-product-card-small .te-img {
  height: calc(200px * 0.8);
}
.te-collection .te-product-card-large .te-img {
  height: calc(200px * 1.2);
}
