.te-modular-cart-product-list,
.te-styled .te-modular-cart-product-list {
  background-color: #ffffff;
  border-bottom: 1px solid #e7e7e7;
}
.te-modular-cart-product-list:last-child,
.te-styled .te-modular-cart-product-list:last-child {
  border: 0;
}
.te-modular-cart-product-list > .te-button-clear,
.te-styled .te-modular-cart-product-list > .te-button-clear {
  width: 100%;
}
.te-modular-cart-product-merchant,
.te-styled .te-modular-cart-product-merchant {
  color: #a3a0a0;
  font-size: 0.7em;
  height: auto;
  overflow: hidden;
  position: relative;
  transition: all 0.5s ease-in-out;
  width: 100%;
}
.te-modular-cart-product-merchant-row,
.te-styled .te-modular-cart-product-merchant-row {
  display: flex;
  line-height: 1.3;
  padding: 5px 0;
}
.te-modular-cart-product-merchant-row:first-child .te-label,
.te-styled .te-modular-cart-product-merchant-row:first-child .te-label {
  white-space: nowrap;
  width: 95px;
}
.te-modular-cart-product-merchant-row:first-child .te-value,
.te-styled .te-modular-cart-product-merchant-row:first-child .te-value {
  color: #a3a0a0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.te-modular-cart-product-merchant-row:last-child,
.te-styled .te-modular-cart-product-merchant-row:last-child {
  padding-bottom: 15px;
}
.te-modular-cart-product-merchant .te-label,
.te-styled .te-modular-cart-product-merchant .te-label {
  color: #4f5058;
}
.te-modular-cart-product-merchant .te-uppercase,
.te-styled .te-modular-cart-product-merchant .te-uppercase {
  text-transform: uppercase;
}
.te-modular-cart-product-merchant .te-value,
.te-styled .te-modular-cart-product-merchant .te-value {
  color: #4f5058;
  padding-left: 10px;
  padding-right: 12px;
}
.te-modular-cart-product-merchant svg,
.te-styled .te-modular-cart-product-merchant svg {
  fill: currentColor;
  height: 10px;
  position: absolute;
  right: 0;
  transform: rotate(180deg);
  width: 10px;
}
.te-modular-cart-product-merchant.closed,
.te-styled .te-modular-cart-product-merchant.closed {
  height: 2.5em;
}
.te-modular-cart-product-merchant.closed svg,
.te-styled .te-modular-cart-product-merchant.closed svg {
  transform: rotate(0deg);
}
.te-modular-cart-product-item,
.te-styled .te-modular-cart-product-item {
  border-top: 1px solid #e7e7e7;
  display: flex;
  flex-direction: column;
  font-size: 0.8em;
  height: 180px;
  padding: 10px 0 0;
}
.te-modular-cart-product-item-wrapper,
.te-styled .te-modular-cart-product-item-wrapper {
  display: flex;
}
.te-modular-cart-product-item-summary,
.te-styled .te-modular-cart-product-item-summary {
  padding-left: 105px;
  display: flex;
  justify-content: space-between;
  flex: 1;
  align-items: center;
}
.te-modular-cart-product-item-summary-delivery,
.te-styled .te-modular-cart-product-item-summary-delivery {
  color: #a3a0a0;
  padding-right: 10px;
}
.te-modular-cart-product-item .te-button-clear,
.te-styled .te-modular-cart-product-item .te-button-clear {
  -webkit-tap-highlight-color: transparent;
  display: block;
}
.te-modular-cart-product-image,
.te-styled .te-modular-cart-product-image {
  background-color: transparent;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  height: 130px;
  margin-right: 15px;
  position: relative;
  width: 90px;
}
.te-modular-cart-product-image:before,
.te-styled .te-modular-cart-product-image:before {
  position: absolute;
  background: #000;
  opacity: 0.05;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  content: '';
}
.te-modular-cart-product-info,
.te-styled .te-modular-cart-product-info {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding-right: 35px;
}
.te-modular-cart-product-remove,
.te-styled .te-modular-cart-product-remove {
  position: absolute;
  right: -10px;
  top: 0;
}
.te-modular-cart-product-remove svg,
.te-styled .te-modular-cart-product-remove svg {
  width: 10px;
  font-size: 10px;
  height: 10px;
}
.te-modular-cart-product-remove button,
.te-styled .te-modular-cart-product-remove button {
  box-sizing: content-box;
}
.te-modular-cart-product-brand,
.te-styled .te-modular-cart-product-brand,
.te-modular-cart-product-name,
.te-styled .te-modular-cart-product-name {
  font-weight: 500;
  line-height: 1.3;
  text-transform: uppercase;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.te-modular-cart-product-brand,
.te-styled .te-modular-cart-product-brand {
  color: #a3a0a0;
}
.te-modular-cart-product-name,
.te-styled .te-modular-cart-product-name {
  color: #4f5058;
}
.te-modular-cart-product-price,
.te-styled .te-modular-cart-product-price {
  line-height: 1.3em;
}
.te-modular-cart-product-price-sales,
.te-styled .te-modular-cart-product-price-sales {
  padding-top: 5px;
}
.te-modular-cart-product-price-sales .te-sales,
.te-styled .te-modular-cart-product-price-sales .te-sales {
  color: #ec877b;
  display: inline-block;
  padding-left: 5px;
}
.te-modular-cart-product-price-sales .te-regular,
.te-styled .te-modular-cart-product-price-sales .te-regular {
  display: inline-block;
  text-decoration: line-through;
}
.te-modular-cart-product-attribute,
.te-styled .te-modular-cart-product-attribute {
  display: flex;
  flex-wrap: wrap;
}
.te-modular-cart-product-attribute span,
.te-styled .te-modular-cart-product-attribute span {
  display: block;
  padding: 0 10px 2px 0;
  max-width: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
}
.te-modular-cart-product-quantity-select,
.te-styled .te-modular-cart-product-quantity-select {
  border: 1px solid #e7e7e7;
  font-size: 0.9em;
  height: 30px;
  margin: 5px 0 0 -1px;
  position: relative;
  width: 85px;
}
.te-modular-cart-product-quantity-select select,
.te-styled .te-modular-cart-product-quantity-select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent;
  border: 0;
  height: 30px;
  outline: none;
  padding: 5px 15px;
  width: 100%;
}
.te-modular-cart-product-quantity-select svg,
.te-styled .te-modular-cart-product-quantity-select svg {
  fill: currentColor;
  height: 10px;
  pointer-events: none;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
}
.te-modular-cart-product-quantity-text,
.te-styled .te-modular-cart-product-quantity-text {
  border: 1px solid #e7e7e7;
  font-size: 0.9em;
  height: 30px;
  margin: 5px 0 0 -1px;
  position: relative;
  width: 85px;
}
.te-modular-cart-product-quantity-text input,
.te-styled .te-modular-cart-product-quantity-text input {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent;
  border: 0;
  height: 29px;
  outline: none;
  padding: 5px 15px;
  width: 100%;
}
.te-modular-cart-product-quantity-text input::-webkit-outer-spin-button,
.te-styled .te-modular-cart-product-quantity-text input::-webkit-outer-spin-button,
.te-modular-cart-product-quantity-text input::-webkit-inner-spin-button,
.te-styled .te-modular-cart-product-quantity-text input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.te-modular-cart-product-line-wrapper,
.te-styled .te-modular-cart-product-line-wrapper {
  display: flex;
  align-items: flex-end;
}
.te-modular-cart-error,
.te-styled .te-modular-cart-error {
  color: #ec877b;
  font-size: 0.8em;
  padding-left: 10px;
}
.te-modular-cart-container-empty,
.te-styled .te-modular-cart-container-empty {
  text-align: center;
}
