.te-tablet-image-wrapper,
div.te-styled .te-product-page .te-tablet-image-wrapper {
  width: 50%;
  margin: 0 auto;
  animation-duration: 1s;
  animation-fill-mode: both;
  background-color: white;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  height: unset;
}
.te-tablet-image,
div.te-styled .te-product-page .te-tablet-image {
  align-items: center;
  display: flex;
  margin-bottom: 20px;
  width: 100%;
}
.te-tablet-thumbnails,
div.te-styled .te-product-page .te-tablet-thumbnails {
  height: 120px;
  width: 100%;
  margin-top: 5px;
}
.te-mobile-image-wrapper,
div.te-styled .te-product-page .te-mobile-image-wrapper {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.te-desktop-image-wrapper,
div.te-styled .te-product-page .te-desktop-image-wrapper {
  display: flex;
  width: 60%;
}
.te-desktop-image,
div.te-styled .te-product-page .te-desktop-image {
  overflow: hidden;
  width: 100%;
}
.te-desktop-thumbnails,
div.te-styled .te-product-page .te-desktop-thumbnails {
  height: 100%;
  margin-right: 20px;
}
