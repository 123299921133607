.te-delivery {
  padding: 0;
  font-size: 1.2em;
}
.te-delivery-list {
  list-style-type: none;
  padding: 0;
  display: block;
}
.te-delivery-item {
  display: flex;
  letter-spacing: 0.08em;
  line-height: 1.6;
}
.te-delivery-item-wrapper {
  align-items: center;
  color: #303248;
  display: flex;
  font-size: 0.85em;
  letter-spacing: 0;
}
.te-delivery-icon-wrapper {
  margin-right: 8px;
}
.te-delivery-icon {
  width: 17px;
  margin-top: 3px;
}
.te-delivery-label {
  margin-right: 5px;
  color: #303248;
  font-size: 0.85em;
}
