.te-popup {
  background-color: white;
  border: 1px solid #333;
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.1), 0 0 2px rgba(0, 0, 0, 0.3);
  color: #0d0d0d;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding: 1em;
  position: fixed;
  right: 55px;
  top: 60px;
  z-index: 9000;
}
.te-popup-title {
  color: #0d0d0d;
  font-family: inherit;
  font-size: 1em;
  font-weight: bold;
  margin: 0 1.5em 1em 0;
  text-transform: uppercase;
}
.te-popup-wrapper {
  box-sizing: border-box;
  z-index: 1;
  min-width: 300px;
  max-width: 100%;
  width: 370px;
}
.te-popup .te-button-cross {
  width: 15px;
  position: absolute;
  right: calc(1em - 10px);
  top: calc(1em - 10px);
  color: #0d0d0d;
}
.te-popup.error {
  color: #cd5550;
}
@media (max-width: 425px) {
  .te-popup {
    width: 90%;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media (min-width: 1350px) {
  .te-popup {
    right: calc((100% - 1350px) / 2 + 55px);
  }
}
