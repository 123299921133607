.te-styled {
  /* HTML5 display-role reset for older browsers */
}
.te-styled html,
.te-styled body,
.te-styled div,
.te-styled span,
.te-styled .span,
.te-styled h1,
.te-styled h2,
.te-styled h3,
.te-styled h4,
.te-styled h5,
.te-styled h6,
.te-styled p,
.te-styled blockquote,
.te-styled pre,
.te-styled a,
.te-styled address,
.te-styled .address,
.te-styled code,
.te-styled del,
.te-styled img,
.te-styled tt,
.te-styled center,
.te-styled .center,
.te-styled dl,
.te-styled dt,
.te-styled dd,
.te-styled ol,
.te-styled ul,
.te-styled li,
.te-styled form,
.te-styled .form,
.te-styled label,
.te-styled .label,
.te-styled table,
.te-styled .table,
.te-styled tbody,
.te-styled tfoot,
.te-styled thead,
.te-styled tr,
.te-styled th,
.te-styled td,
.te-styled article,
.te-styled .article,
.te-styled aside,
.te-styled .aside,
.te-styled figure,
.te-styled .figure,
.te-styled figcaption,
.te-styled footer,
.te-styled .footer,
.te-styled header,
.te-styled .header,
.te-styled nav,
.te-styled .nav,
.te-styled section,
.te-styled .section,
.te-styled audio,
.te-styled video {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: inherit;
  vertical-align: baseline;
}
.te-styled article,
.te-styled aside,
.te-styled footer,
.te-styled header,
.te-styled hgroup,
.te-styled menu,
.te-styled nav,
.te-styled section {
  display: block;
}
.te-styled body {
  line-height: 1;
  color: #333;
}
.te-styled ol,
.te-styled ul {
  list-style: none;
}
div.te-styled .te-product-page {
  font-size: 16px;
  font-family: 'Gotham', 'Arial', sans-serif;
}
div.te-styled .te-product-page * {
  box-sizing: border-box;
}
div.te-styled .te-product-page .te-details {
  margin-bottom: 3px;
}
div.te-styled .te-product-page .te-details-brand,
div.te-styled .te-product-page .te-details-name {
  padding-right: 20px;
}
div.te-styled .te-product-page .te-basic-price.te-desktop {
  margin-bottom: 80px;
}
div.te-styled .te-product-page .te-basic-price-main {
  margin-bottom: 10px;
}
div.te-styled .te-product-page .te-basic-price-main-sales {
  margin-left: 20px;
}
div.te-styled .te-product-page .te-basic-price-main-discount {
  margin-top: 5px;
  font-size: 1.25em;
}
div.te-styled .te-product-page .te-basic-price-main-vat {
  margin-left: 10px;
}
div.te-styled .te-product-page .te-color-relations-tile {
  margin: 4px 0;
}
div.te-styled .te-product-page .te-modular-dropdown-select {
  border: 1px solid #e7e7e7;
}
div.te-styled .te-product-page .te-modular-dropdown-title {
  border: 1px solid #e7e7e7;
  padding: 11px 20px 11px 15px;
}
div.te-styled .te-product-page .te-modular-dropdown-menu-wrapper {
  border-left: 1px solid #e7e7e7;
  border-right: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
}
div.te-styled .te-product-page .te-modular-dropdown-menu-item-wrapper {
  padding: 10px 15px;
}
div.te-styled .te-product-page .te-delivery-icon-wrapper {
  margin: 0 8px 5px 0;
  display: flex;
  align-items: center;
}
div.te-styled .te-product-page .te-style-with {
  margin: 0 10px 0 20px;
}
div.te-styled .te-product-page .te-style-with-inner.te-desktop {
  margin-bottom: 65px;
  margin-top: 50px;
}
div.te-styled .te-product-page .te-style-with-title {
  margin: 15px 0;
}
div.te-styled .te-product-page .te-style-with-products-image-container {
  margin-bottom: 15px;
  height: 200px;
}
div.te-styled .te-product-page .te-style-with-products-brand.te-desktop,
div.te-styled .te-product-page .te-style-with-products-name.te-desktop {
  margin-bottom: 5px;
  text-transform: uppercase;
}
div.te-styled .te-product-page .te-style-with-products-price-sales {
  margin-left: 10px;
}
div.te-styled .te-product-page .te-description {
  margin: 20px 20px 40px 20px;
}
div.te-styled .te-product-page .te-description-title {
  margin: 50px 0 30px 0;
}
div.te-styled .te-product-page .te-description-content {
  font-size: 14px;
  line-height: 1.4em;
}
div.te-styled .te-product-page .te-similar {
  margin: 0 20px 50px 0;
}
div.te-styled .te-product-page .te-similar-title {
  margin: 15px 20px;
}
div.te-styled .te-product-page .te-similar-products-container {
  margin: 10px 0 25px 20px;
}
div.te-styled .te-product-page .te-similar-products-brand.te-desktop {
  margin: 15px 0 5px 0;
}
div.te-styled .te-product-page .te-similar-products-name.te-desktop {
  margin-bottom: 5px;
  text-transform: uppercase;
}
div.te-styled .te-product-page .te-similar-products-price-sales {
  margin-left: 10px;
}
div.te-styled .te-product-page .te-desktop.te-product-container {
  padding: 0 20px;
}
div.te-styled .te-product-page .te-desktop .te-details-brand,
div.te-styled .te-product-page .te-desktop .te-details-name {
  line-height: 1em;
  margin-bottom: 10px;
}
div.te-styled .te-product-page .te-desktop .te-basic-price {
  margin-bottom: 80px;
}
div.te-styled .te-product-page .te-desktop .te-description-content {
  padding: 10px 23px 50px 23px;
}
div.te-styled .te-product-page .te-tablet .te-product-container-details {
  padding-left: 20px;
}
div.te-styled .te-product-page .te-tablet .te-description {
  padding: 0 40px 50px;
  font-size: 1em;
}
div.te-styled .te-product-page.te-mobile .te-product-container-details {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  margin-top: 10px;
  margin-bottom: 20px;
}
div.te-styled .te-product-page.te-mobile .te-modular-image .te-image {
  max-height: 100vh;
}
div.te-styled .te-product-page.te-mobile .te-delivery {
  order: 6;
  margin-top: 20px;
}
div.te-styled .te-product-page.te-mobile .te-modular-dropdown {
  margin-bottom: 30px;
}
div.te-styled .te-product-page.te-mobile .te-description {
  margin: 20px 0 40px;
  padding: 0 20px 30px;
}
div.te-styled .te-product-page.te-mobile .te-description-content {
  font-size: 12px;
  line-height: 1.5em;
}
div.te-styled .te-product-page.te-mobile .te-description-title {
  font-size: 14px;
}
div.te-styled .te-product-page.te-mobile .te-basic-price {
  margin-top: 0;
}
div.te-styled .te-product-page.te-mobile .te-basic-price-main-discount {
  font-size: 0.875em;
  margin-bottom: 20px;
}
div.te-styled .te-product-page .te-tablet-thumbnails {
  margin-top: 5px;
}
div.te-styled .te-product-page .te-tablet-image-wrapper {
  width: 50%;
}
